const authors = [
  {
    name: 'Buscando Mejorar',
    imageUrl: 'gleny.jpg',
    bio:
      'Blogger',
    facebook: 'https://www.facebook.com/',
    twitter: 'https://www.twitter.com/',
    instagram: 'https://www.instagram.com/blogbuscandomejorar',
    google: 'https://www.google.com/',
    linkedin: 'https://www.linkedin.com/',
  } 
]

module.exports = authors
